.loginMain {
    height: 100vh;
    width: 100vw;
    background-image: url("./images//7973193_3398605.jpg");
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container {
    display: flex;
    flex-direction: column;
  
    
    width: 300px;
    border: 5px solid white;
    background-color: rgb(54, 116, 204);
    border-radius: 20px;
    padding-bottom: 20px;
  }
  
  
  
  .circle {
    display: flex;
    position: relative;
  
    top: -25px;
    justify-content: center;
  }
  .circle>div{
    height:60px;
    width:60px;
    background-image: url(./images/lock@2x.png);
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px ;
    background-color: antiquewhite;
    background-size: 80px;
  }
  .title {
    display: flex;
    height: 100px;
  
    border-bottom: 2px solid rgb(235, 236, 230);
    margin-bottom: 10px;
  }
  .titleText {
    width: 150px;
    margin: 10px;
    text-align: center;
    font-size: large;
  }
  .titleText > p {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(223, 226, 211);
    color:crimson;
    height:40px;
    font-weight: 900;
  }
  .image {
    width: 150px;
    /* background-image: url("./images//7973193_3398605.jpg"); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .form {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  .labInput{
    margin-left: 10px;
    margin-bottom: 15px;
  }
  
  .labInput> p {
    margin-left: 25px;
    color: black;
    font-weight: bold;
  }
  .labInput > div > input {
    font-weight: 700;
  color:blanchedalmond;
    width: 230px;
    background: transparent;
    border: none;
    border-bottom: 2px solid rgb(252, 252, 252);
    outline: none;
    margin: 7px;
  }
  
  .forgotPass {
    display: flex;
    display: none;
    justify-content: flex-end;
    margin: -2px 10px 5px 0px;
    color:rgb(209, 192, 11)
  }
  .button {
    display: flex;
    justify-content: center;
    margin: 10px 0px 5px 0px;
    font-weight: 900;
  }
  .button > button {
    width: 150px;
    height:40px;
    border-radius: 20px;
    font-weight: 900;
  }
  .signupbtn {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .signupbtn > p {
    display: flex;
    justify-content: center;
    margin: 5px;
    font-weight: 700;
  }
  .signupbtn > a {
    display: flex;
    justify-content: center;
    margin: 0px;
    color:antiquewhite;
    width:100px;
    margin-left: 33%;
   
  }
  .signupbtn>a:hover{
    cursor:pointer
  }
  .icon {
    color: rgb(255, 255, 254);
    font-weight: bold;
    
  }
  input[type="password"] {
    background-color: transparent; /* Set your desired background color */
  }