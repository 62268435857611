
  .signupMain {
    height: 100vh;
  width:100vw;
  background-image: url("./images//7973193_3398605.jpg");
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(210, 204, 204);
  }
  .container {
    display: flex;
    flex-direction: column;
    background-color: rgb(29, 109, 131);
    width: 300px;
  
    border-radius: 20px;
    padding-bottom: 20px;
  }
  .circle {
    display: flex;
    position: relative;
    justify-content: center;
    top: -25px;
   
  }
  .circle>div{
    height:60px;
    width:60px;
    /* background-image: url(/public/ftlogin.png); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px ;
    background-color: antiquewhite;
    background-size: 100px;
  }
  .title {
    display: flex;
    height: 100px;
  
    border-bottom: 2px solid rgb(175, 212, 9);
    margin-bottom: 10px;
  }
  .titleText {
    width: 150px;
    margin: 10px;
    text-align: center;
    font-size: large;
  }
  .titleText > p {
    display: flex;
    align-items: center;
    background-color: yellowgreen;
    height:40px;
    font-weight: 900;
  }
  .image {
    width: 150px;
    
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .form {
    display: flex;
    flex-direction: column;
  }
  .inputTab {
    margin-left: 10px;
    margin-bottom: 15px;
  }
  .inputTab > p {
    margin-left: 25px;
    color: black;
    font-weight: bold;
  }
  .inputTab > div > input {
    font-weight: 700;
  
    width: 230px;
    background: transparent;
    border: none;
    border-bottom: 1px solid rgb(0, 0, 0);
    outline: none;
    margin: 7px;
    color:white
  }
  /* .inputTab > div > input:hover {
    box-shadow: -0px 4px 3px -2px rgb(64, 160, 26);
    border-bottom: none;
  } */
  
  .signupbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }
  .signupbtn > button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 40px;
    border-radius: 20px;
    background-color: yellowgreen;
    font-weight: bold;
  }
  .loginbtn > p {
    display: flex;
    justify-content: center;
    margin: 5px;
    font-weight: 900;
  }
  .loginbtn > a {
    display: flex;
    justify-content: center;
    color: rgb(208, 171, 22);
    text-decoration: none;
    font-weight: bold;
    width:100px;
    margin-left: 33%;;
  }
  .loginbtn>a>button:hover{
    cursor:pointer
  }
  .loginbtn > a > button {
    width: 100px;
    height: 30px;
    border-radius: 20px 0px 20px 0px;
    background-color:rgb(189, 169, 38);
    font-weight: bold;
  }
  .icon {
    color: rgb(209, 211, 204);
    font-weight: bold;
    
  }