.offersContainer {
  display: inline-block;
  white-space: nowrap;
  overflow-x: auto;
  width: 100%;
}

.offersCard {
  display: inline-block;
  justify-content: center;
  align-items: center;
  
 width: 80%;
  margin-right: 10px;
  border: 1px solid rgb(48, 46, 46);
box-shadow: 3px 2px 6px rgb(56, 56, 148);
  
}
.offersImg{
    /* background: url("./images//herobg.jpg"); */
    /* background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    width:100%;
    height: 100%;
}
.offersContainer::-webkit-scrollbar {
    width: 0; /* Hide scrollbar in Webkit browsers (Chrome, Safari) */
  }
@media(min-width:576px){
    .offersCard {
        width:60%
    }  
}
@media(min-width:768px){
    .offersCard {
        width:45%
    }  
}
@media(min-width:992px){
    .offersCard {
        width:40%
    }  
}
@media(min-width:992px){
    .offersCard {
        width:30%
    }  
}
