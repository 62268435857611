.wrapper{
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(10px);
   
    border-radius: 15px;
    
  }
  @media(max-width:768px){
    .wrapper{
        background: none;
        backdrop-filter: none;
        border-radius: 0px;
    }
  }